import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'taxgroup',
  components: {},
  watch: {
    currentPage: function() {
      this.getTaxGroupList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTaxGroupList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      payload: {},
      totalRows: null,
      taxGroupSearch: '',
      data: [],
      fields: [
        {
          key: 'tax_group'
        },
        {
          key: 'tax_type_group_value'
        },
        {
          key: 'org_type',
          label: 'Org Type'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'taxGroup/getTaxGroupList',
            'tax-group',
            () => (this.loader = false)
          );
        }
      }
    });
    // this.getTaxGroupList();
  },
  methods: {
    clearTaxGroup() {
      this.taxGroupSearch = null;
    },
    getTaxGroupList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        tax_group: this.taxGroupSearch
      };
      this.$store
        .dispatch('taxGroup/getTaxGroupList', this.payload)
        .then(response => {
          if (response.status === 200) {
            this.data = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        });
    },
    rowSelected(rowData) {
      this.$emit('selectTaxGroup', rowData);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
