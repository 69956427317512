import commonHelper from '@/app/utility/common.helper.utility';
import TaxSectionDetails from './taxSectionDetails';
export default {
  name: 'TaxSection',
  components: {
    TaxSectionDetails
  },
  watch: {
    currentPage: function() {
      this.getTaxSectionList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTaxSectionList();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      loader: false,
      editMode: false,
      updateMode: false,
      unsubscribe: null,
      payload: {},
      sectionName: null,
      showTaxSectionDetails: false,
      selectedTaxSectionData:null,
      taxSectionList: [],
      taxSectionFields: [
        {
          key: 'tax_group_id',
          class: 'd-none'
        },
        {
          key: 'tax_group'
        },
        {
          key: 'section'
        },
        {
          key: 'tax_section_id',
          class: 'd-none'
        },
        {
          key: 'active'
        }
      ]
    };
  },
  mounted() {
    this.getTaxSectionList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.updateMode = true;
          this.selectedTaxSectionData=null;
          this.showTaxSectionDetails = true;
        }
        if (actionName === 'download' && !this.showTaxSectionDetails) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'defineTaxSection/getTaxSectionList',
            'Tax-Sec',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getTaxSectionList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        section: this.sectionName
      };
      this.loader = true;
      this.$store
        .dispatch('defineTaxSection/getTaxSectionList', this.payload)
        .then(response => {
          this.loader = false;
          this.taxSectionList = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateSectionList() {
      this.getTaxSectionList();
    },
    rowSelected(rowData) {
      this.updateMode = false;
      if (this.$router.currentRoute.name === 'SearchtaxSection') {
        this.showTaxSectionDetails = true;
        this.selectedTaxSectionData=rowData;
      } else {
        this.$emit('selectTaxSection', rowData);
      }
    },
    clearSearch() {
      this.taxSectionList = [];
      this.totalRows = null;
      this.sectionName = null;
    },
    hideTaxSectionDetails() {
      this.showTaxSectionDetails = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
